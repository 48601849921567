//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      hide: false,
      showMobileMenu: false,
    };
  },

  methods: {
    toggleMenu() {
      this.showMobileMenu = !this.showMobileMenu;

      if (this.showMobileMenu === true) {
        document.body.style = "overflow: hidden";
      } else document.body.style = "overflow: scroll";
    },
  },

  watch: {
    $route(to, from) {
      this.showMobileMenu = false;
    },
  },
};
